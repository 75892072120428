import { createAction, props } from '@ngrx/store';

import { CompletedAccommodation } from '../../models';

export const loadRequest = createAction(
  '[Completed Accommodations] Load Request',
  props<{ properties: number[]; referenceDate: Date }>(),
);

export const loadsSuccess = createAction(
  '[Completed Accommodations] Load Success',
  props<{ items: CompletedAccommodation[] }>(),
);

export const loadFailure = createAction(
  '[Completed Accommodations] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[CompletedAccommodations] Reset State');
