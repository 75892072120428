import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ZipCodeDestinationsStoreEffects } from './effects';
import { ZipCodeDestinationsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('zipCodeDestinations', ZipCodeDestinationsReducer),
    EffectsModule.forFeature([ZipCodeDestinationsStoreEffects]),
  ],
})
export class ZipCodeDestinationsStoreModule {}
