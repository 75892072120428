import { Action, createReducer, on } from '@ngrx/store';

import { ZdlakeSourceCountries } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      chartData: onFormatChartData(items),
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function SourceCountriesChartReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onFormatChartData(data: ZdlakeSourceCountries[]): ChartLineItem[] {
  return data.map(({ count, country_code, country_name }) => {
    return {
      value: count,
      name: country_code,
      extra: { country_name },
    };
  });
}
