import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { add, sub } from 'date-fns';
import moment from 'moment';

import { StatsPreset } from '../../../../models';

export const LAST_90_DAYS: [Date, Date] = [
  sub(new Date(), { days: 90 }),
  new Date(),
];

export const NEXT_30_DAYS: [Date, Date] = [
  new Date(),
  add(new Date(), { days: 30 }),
];

export const LAST_YEAR_RANGE: [Date, Date] = [
  moment(new Date()).subtract(1, 'years').toDate(),
  new Date(),
];

export const NEXT_YEAR_RANGE: [Date, Date] = [
  moment(new Date()).add(1, 'years').startOf('years').toDate(),
  moment(new Date()).add(1, 'years').endOf('years').toDate(),
];

@Injectable()
export class ZdlakeRangesPresetsService {
  constructor(private translate: TranslateService) {}

  defaultPresets(): StatsPreset[] {
    return [
      {
        key: 'last_items_number',
        translateParams: {
          items: 7,
          gender: 'male',
          itemsName: this.translate.instant('days'),
        },
        value: [moment(new Date()).subtract(7, 'days').toDate(), new Date()],
      },
      {
        key: 'last_items_number',
        translateParams: {
          items: 30,
          gender: 'male',
          itemsName: this.translate.instant('days'),
        },
        value: [moment(new Date()).subtract(30, 'days').toDate(), new Date()],
      },
      {
        key: 'last_items_number',
        translateParams: {
          items: 3,
          gender: 'male',
          itemsName: this.translate.instant('months'),
        },
        value: [moment(new Date()).subtract(3, 'month').toDate(), new Date()],
      },
      {
        key: 'range_period_message',
        translateParams: {
          startPeriodName: this.translate.instant('year'),
          endPeriodName: this.translate.instant('today'),
        },
        value: [moment(new Date()).startOf('years').toDate(), new Date()],
      },
    ];
  }

  get searchDatesPresets(): StatsPreset[] {
    return [
      ...this.defaultPresets(),
      {
        key: 'last_year',
        value: [moment(new Date()).subtract(1, 'years').toDate(), new Date()],
      },
    ];
  }

  get searchStayDatesPresets(): StatsPreset[] {
    return [
      ...this.defaultPresets(),
      {
        key: 'next_year',
        value: [
          moment(new Date()).add(1, 'years').startOf('years').toDate(),
          moment(new Date()).add(1, 'years').endOf('years').toDate(),
        ],
      },
    ];
  }
}
