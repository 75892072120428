import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { StatsWidgetResponse } from '../../models';
import { AppStore } from '../../models/types/app-store';
import { StatsWidgetService } from '../../services/stats-widgets.service';

import * as fromActions from './actions';

@Injectable()
export class AdrRevparChartWidgetStoreEffects {
  constructor(
    private dataService: StatsWidgetService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private appStore: Store<AppStore>,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, dateFrom, dateTo }) =>
        this.dataService.load(propertiesIds, dateFrom, dateTo).pipe(
          map(({ data }: IResponseSuccess<StatsWidgetResponse>) =>
            fromActions.loadSuccess({
              data,
              timing: 'current_year',
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadLastYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLastYearRequest),
      withLatestFrom(this.appStore),
      switchMap(
        ([{ propertiesIds, dateFrom, dateTo }, { adrRevparChartWidget }]) => {
          const lastYearDataExist = !!adrRevparChartWidget.chartLines?.find(
            ({ name }) => name.includes('last_year'),
          );

          if (lastYearDataExist) {
            return of(fromActions.toggleLastYear({ visible: true }));
          }

          this.appStore.dispatch(fromActions.setLoading({ isLoading: true }));

          return this.dataService.load(propertiesIds, dateFrom, dateTo).pipe(
            map(({ data }: IResponseSuccess<StatsWidgetResponse>) =>
              fromActions.loadSuccess({
                data,
                timing: 'last_year',
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          );
        },
      ),
    ),
  );
}
