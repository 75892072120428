import { createFeatureSelector, createSelector } from '@ngrx/store';

import { StatisticCategory } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getCategory = (category: StatisticCategory) => (state: State) =>
  state[category];

export const selectState = createFeatureSelector<State>('statistics');

export const selectError = createSelector(
  selectState,
  getError,
);

export const selectIsLoading = createSelector(
  selectState,
  getIsLoading,
);

export const selectByCategory = (category: StatisticCategory) =>
  createSelector(
    selectState,
    getCategory(category),
  );
