import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DateOfCheckinStoreEffects } from './effects';
import { DateOfCheckinReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('dateOfCheckin', DateOfCheckinReducer),
    EffectsModule.forFeature([DateOfCheckinStoreEffects]),
  ],
})
export class DateOfCheckinStoreModule {}
