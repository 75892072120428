import { createAction, props } from '@ngrx/store';

import { Reservation } from '../../models';

export const loadRequest = createAction(
  '[Expiring Reservations Option] Load Request',
  props<{ properties: number[]; referenceDate: Date }>(),
);

export const loadsSuccess = createAction(
  '[Expiring Reservations Option] Load Success',
  props<{ items: Reservation[] }>(),
);

export const loadFailure = createAction(
  '[Expiring Reservations Option] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Expiring Reservations Option] Reset State',
);
