import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import { PriceQuotationsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ExpiringPriceQuotationOptionsStoreEffects {
  constructor(
    private dataService: PriceQuotationsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private dateFormatter: DateFormatterService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ properties, referenceDate }) =>
        this.dataService
          .load(1, properties, {
            expire_date_availability: this.dateFormatter.toServerFormat(
              referenceDate,
            ),
          })
          .pipe(
            map((response: IResponseSuccess) =>
              fromActions.loadsSuccess({
                items: response.data,
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );
}
