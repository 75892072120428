import { createAction, props } from '@ngrx/store';

import { StatsWidgetResponse } from '../../models';

export const loadRequest = createAction(
  '[Daily Occupancy Chart Widget] Load Request',
  props<{ propertiesIds: number[]; currentDate: Date }>(),
);

export const loadSuccess = createAction(
  '[Daily Occupancy Chart Widget] Load Success',
  props<{
    data: StatsWidgetResponse;
  }>(),
);
export const loadFailure = createAction(
  '[Daily Occupancy Chart Widget] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Daily Occupancy Chart Widget] Reset State',
);
