import {
  AdrStat,
  OccupancyStat,
  ProductionStat,
  ReservationStat,
  RevenueStat,
  SoldNightsStat,
} from '../../models';

export interface State {
  adr?: AdrStat;
  production?: ProductionStat;
  reservations?: ReservationStat;
  revenue?: RevenueStat;
  soldNights?: SoldNightsStat;
  occupancy?: OccupancyStat;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  isLoading: false,
  error: null,
};
