import { createAction, props } from '@ngrx/store';

import {
  ZdlakeQuestionsDatesOfSearch,
  ZdlakeSearchRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Questions dates of Search] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Questions dates of Search] Load Success',
  props<{ items: ZdlakeQuestionsDatesOfSearch[] }>(),
);
export const loadFailure = createAction(
  '[Questions dates of Search] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Questions dates of Search] Reset State',
);
