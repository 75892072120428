import { Action, createReducer, on } from '@ngrx/store';

import { ZdlakeTypeOfGuests } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) => {
    const chartData = onFormatChartData(items);
    return fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      chartData,
      total: chartData.reduce((acc, { value }) => (acc = acc + value), 0),
    });
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function TypeOfGuestsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onFormatChartData(data: ZdlakeTypeOfGuests[]) {
  return data.map(({ count, label }) => {
    return {
      value: count,
      name: label,
    };
  }) as ChartLineItem[];
}
