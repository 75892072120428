import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RevenueChartWidgetStoreEffects } from './effects';
import { revenueChartWidgetReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('revenueChartWidget', revenueChartWidgetReducer),
    EffectsModule.forFeature([RevenueChartWidgetStoreEffects]),
  ],
})
export class RevenueChartWidgetStoreModule {}
