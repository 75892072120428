import { createAction, props } from '@ngrx/store';

import { ZdlakeDateOfCheckin, ZdlakeSearchRequest } from '../../models';

export const loadRequest = createAction(
  '[Date Of Checkin] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Date Of Checkin] Load Success',
  props<{ items: ZdlakeDateOfCheckin[]; order?: string }>(),
);
export const loadFailure = createAction(
  '[Date Of Checkin] Load Failure',
  props<{ error: any }>(),
);

export const orderRequest = createAction(
  '[Date Of Checkin] order request',
  props<{ order: string }>(),
);

export const orderSuccess = createAction(
  '[Date Of Checkin] order success',
  props<{ order: string }>(),
);

export const resetState = createAction('[Date Of Checkin] Reset State');
