import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ZdlakeZipCodeDestinations } from '../../models';
import { ZdlakeService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class ZipCodeDestinationsStoreEffects {
  constructor(
    private dataService: ZdlakeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map((response: IResponseSuccess<ZdlakeZipCodeDestinations[]>) =>
            fromActions.loadSuccess({
              items: response.data,
              order: request?.order,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadSuccess),
      switchMap(({ order }) => of(fromActions.orderRequest({ order }))),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(fromActions.loadFailure(error));
      }),
    ),
  );

  order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.orderRequest),
      switchMap(({ order }) =>
        of(fromActions.orderSuccess({ order })).pipe(delay(250)),
      ),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(fromActions.loadFailure(error));
      }),
    ),
  );
}
