import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeLengthOfStay } from '../../models';
import { ChartLineSingle } from '../../models/objects/chart-line';

export const featureAdapter: EntityAdapter<ZdlakeLengthOfStay> =
  createEntityAdapter<ZdlakeLengthOfStay>({
    selectId: (model) => model.lenght_of_stay,
  });

export interface State extends EntityState<ZdlakeLengthOfStay> {
  isLoading?: boolean;
  error?: any;
  chartData: ChartLineSingle[];
  total: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  chartData: [],
  total: 0,
});
