import { ChartLine } from '../../models/objects/chart-line';

export interface State {
  chartLines: ChartLine[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  chartLines: [],
  isLoading: false,
  error: null,
};
