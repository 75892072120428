import { createAction, props } from '@ngrx/store';

import { ZdlakeQuestionsDatesOfStay, ZdlakeSearchRequest } from '../../models';

export const loadRequest = createAction(
  '[Questions dates of Stay] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Questions dates of Stay] Load Success',
  props<{ items: ZdlakeQuestionsDatesOfStay[] }>(),
);
export const loadFailure = createAction(
  '[Questions dates of Stay] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Questions dates of Stay] Reset State');
