import { Action, createReducer, on } from '@ngrx/store';

import { ZdlakeQuestionsDatesOfStay } from '../../models';
import { ChartLineSingle } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      chartData: onFormatChartData(items),
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function QuestionsDatesOfStayReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onFormatChartData(
  data: ZdlakeQuestionsDatesOfStay[],
): ChartLineSingle[] {
  return data.map(({ count, date }) => {
    return {
      value: count,
      name: date,
    };
  }) as ChartLineSingle[];
}
