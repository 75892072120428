import { createAction, props } from '@ngrx/store';

import { StatsWidgetResponse } from '../../models';

export const loadRequest = createAction(
  '[Revenue Chart Widget] Load Request',
  props<{ propertiesIds: number[]; dateFrom: Date; dateTo: Date }>(),
);
export const loadSuccess = createAction(
  '[Revenue Chart Widget] Load Success',
  props<{ revenueData: StatsWidgetResponse }>(),
);
export const loadFailure = createAction(
  '[Revenue Chart Widget] Load Failure',
  props<{ error: any }>(),
);

export const loadLastYearRequest = createAction(
  '[Revenue Chart Widget] Load Last Year Request',
  props<{ propertiesIds: number[]; dateFrom: Date; dateTo: Date }>(),
);
export const loadLastYearSuccess = createAction(
  '[Revenue Chart Widget] Load Last Year Success',
  props<{ revenueData: StatsWidgetResponse }>(),
);
export const loadLastYearFailure = createAction(
  '[Revenue Chart Widget] Load Last Year Failure',
  props<{ error: any }>(),
);

export const toggleLastYear = createAction(
  '[Revenue Chart Widget] Toggle Last Year',
  props<{ visible: boolean }>(),
);

export const setLoading = createAction(
  '[Revenue Chart Widget] Set Loading',
  props<{ isLoading: boolean }>(),
);

export const resetState = createAction('[Revenue Chart Widget] Reset State');
