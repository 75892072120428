import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { PriceQuotation } from '../../models';

export const featureAdapter: EntityAdapter<
  PriceQuotation
> = createEntityAdapter<PriceQuotation>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<PriceQuotation> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
