import {
  LAST_90_DAYS,
  NEXT_30_DAYS,
} from '../../features/commons/stats-zdlake/zdlake-range-picker/zdlake-ranges-presets.service';
import { ZdlakeFilters } from '../../models';

export const FILTERS_VALUE_DEFAULT: ZdlakeFilters = {
  search_dates: LAST_90_DAYS,
  search_stay_dates: NEXT_30_DAYS,
  group: 'day',
  radius_in_km: 20,
  property_id: null,
};

export interface State {
  isLoading?: boolean;
  error?: any;
  filtersValue: ZdlakeFilters;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  filtersValue: FILTERS_VALUE_DEFAULT,
};
