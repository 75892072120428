import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions } from '@ngrx/effects';

import { ZdlakeService } from '../../services';

@Injectable()
export class ZdlakeStoreEffects {
  constructor(
    private dataService: ZdlakeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}
}
