import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LengthOfStayStoreEffects } from './effects';
import { LengthOfStayReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('lengthOfStay', LengthOfStayReducer),
    EffectsModule.forFeature([LengthOfStayStoreEffects]),
  ],
})
export class LengthOfStayStoreModule {}
