import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { QuestionsDatesOfStayStoreEffects } from './effects';
import { QuestionsDatesOfStayReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('questionsDatesOfStay', QuestionsDatesOfStayReducer),
    EffectsModule.forFeature([QuestionsDatesOfStayStoreEffects]),
  ],
})
export class QuestionsDatesOfStayStoreModule {}
