import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ZdlakeSourceCountries } from '../../models';
import { ZdlakeService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class SourceCountriesChartStoreEffects {
  constructor(
    private dataService: ZdlakeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map((response: IResponseSuccess<ZdlakeSourceCountries[]>) =>
            fromActions.loadSuccess({ items: response.data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
