import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CompletedAccommodationsStoreEffects } from './effects';
import { completedAccommodationsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'completedAccommodations',
      completedAccommodationsReducer,
    ),
    EffectsModule.forFeature([CompletedAccommodationsStoreEffects]),
  ],
})
export class CompletedAccommodationsStoreModule {}
