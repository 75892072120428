import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { DateFormatterService } from '../core/services/date-formatter.service';

@Injectable({
  providedIn: 'root',
})
export class StatsWidgetService {
  constructor(
    private http: HttpClient,
    private dateFormatter: DateFormatterService,
  ) {}

  load(property_ids: number[], date_from: Date, date_to: Date) {
    return this.http.get(
      `statistics/dashboard_widget?${generateSearchQuery(
        this.dateFormatter.formatObjectDates({
          property_ids,
          date_from,
          date_to,
        }),
      )}`,
    );
  }
}
