import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { invoices }) => {
    const newInvoices = invoices.map((invoice) => {
      const { sdi_notify } = invoice;
      const rejectedNotify = (sdi_notify || []).filter(
        ({ status_sdi }) => status_sdi === 2,
      );
      return {
        ...invoice,
        rejectedNotify: rejectedNotify[rejectedNotify.length - 1] || null,
      };
    });
    return fromState.featureAdapter.setAll(newInvoices, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function rejectedInvoicesWidgetReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
