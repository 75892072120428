import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeZipCodeDestinations } from '../../models';

export const featureAdapter: EntityAdapter<ZdlakeZipCodeDestinations> =
  createEntityAdapter<ZdlakeZipCodeDestinations>({
    selectId: (model) => model.city_name,
  });

export interface State extends EntityState<ZdlakeZipCodeDestinations> {
  isLoading?: boolean;
  error?: any;
  originalOrder: string[];
  order: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  originalOrder: null,
  order: null,
});
