import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeQuestionsDatesOfSearch } from '../../models';
import { ChartLine } from '../../models/objects/chart-line';

export const featureAdapter: EntityAdapter<ZdlakeQuestionsDatesOfSearch> =
  createEntityAdapter<ZdlakeQuestionsDatesOfSearch>({
    selectId: (model) => model.date,
  });

export interface State extends EntityState<ZdlakeQuestionsDatesOfSearch> {
  isLoading?: boolean;
  error?: any;
  chartData: ChartLine[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  chartData: [],
});
