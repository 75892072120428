import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeSourceCountries } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

export const featureAdapter: EntityAdapter<ZdlakeSourceCountries> =
  createEntityAdapter<ZdlakeSourceCountries>({
    selectId: (model) => model.country_code,
  });

export interface State extends EntityState<ZdlakeSourceCountries> {
  isLoading?: boolean;
  error?: any;
  chartData: ChartLineItem[];
  originalOrder: string[];
  order: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  chartData: [],
  originalOrder: null,
  order: null,
});
