import { createAction, props } from '@ngrx/store';

import { PriceQuotation } from '../../models';

export const loadRequest = createAction(
  '[Expiring Price Quotation Options] Load Request',
  props<{ properties: number[]; referenceDate: Date }>(),
);

export const loadsSuccess = createAction(
  '[Expiring Price Quotation Options] Load Success',
  props<{ items: PriceQuotation[] }>(),
);

export const loadFailure = createAction(
  '[Expiring Price Quotation Options] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Expiring Price Quotation Options] Reset State',
);
