import { createAction, props } from '@ngrx/store';

import { LoadStatisticsRequest, StatisticCategory } from '../../models';

export const loadRequest = createAction(
  '[Statistics] Load Request',
  props<LoadStatisticsRequest>(),
);

export const loadSuccess = createAction(
  '[Statistics] Load Success',
  props<{
    category: StatisticCategory;
    data: any;
  }>(),
);

export const loadFailure = createAction(
  '[Statistics] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Statistics] Reset State');
