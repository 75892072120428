import { createAction, props } from '@ngrx/store';

import { ZdlakeFilters } from '../../models';

export const setFiltersValue = createAction(
  '[Zdlake] set Filters value',
  props<{
    filtersValue: Partial<ZdlakeFilters>;
  }>(),
);

export const resetState = createAction('[Zdlake] Reset State');
