import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChartLineSingle } from '../../models/objects/chart-line';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'dailyOccupancyChartWidget',
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectData = createSelector(
  selectState,
  (state: State) => state.data,
);

export const selectChartLines = createSelector(
  selectData,
  (data): ChartLineSingle[] => {
    if (!data) {
      return [];
    }

    return [
      { name: 'occupied_accommodations', value: data?.sold_nights },
      { name: 'available_accommodations', value: data?.unsold_nights },
    ];
  },
);
