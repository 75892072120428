import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { StatisticsStoreEffects } from './effects';
import { statisticsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('statistics', statisticsReducer),
    EffectsModule.forFeature([StatisticsStoreEffects]),
  ],
})
export class StatisticsStoreModule {}
