import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeTypeOfGuests } from '../../models';
import { ChartLineItem } from '../../models/objects/chart-line';

export const featureAdapter: EntityAdapter<ZdlakeTypeOfGuests> =
  createEntityAdapter<ZdlakeTypeOfGuests>({
    selectId: (model) => model.label,
  });

export interface State extends EntityState<ZdlakeTypeOfGuests> {
  isLoading?: boolean;
  error?: any;
  chartData: ChartLineItem[];
  total: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  chartData: [],
  total: null,
});
