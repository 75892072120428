import { ChartLine, ChartLineItem } from '../../models/objects/chart-line';

export interface State {
  currentYear: ChartLineItem[];
  lastYear: ChartLine[];
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  currentYear: [],
  lastYear: null,
  isLoading: false,
  error: null,
};
