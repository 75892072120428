import { Action, createReducer, on } from '@ngrx/store';

import { sortData } from '../../helpers';
import { ZdlakeDateOfStay } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items, order }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      originalOrder: items.map(({ date }) => date),
      order,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.orderRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.orderSuccess, (state, { order }) => {
    return {
      ...sortData<ZdlakeDateOfStay, fromState.State>(
        state,
        order,
        fromState.featureAdapter,
      ),
    };
  }),

  on(fromActions.resetState, () => fromState.initialState),
);

export function DateOfStayReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
