import { createAction, props } from '@ngrx/store';

import { ZdlakeLengthOfStay, ZdlakeSearchRequest } from '../../models';

export const loadRequest = createAction(
  '[Length Of Stay ] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Length Of Stay ] Load Success',
  props<{ items: ZdlakeLengthOfStay[] }>(),
);
export const loadFailure = createAction(
  '[Length Of Stay ] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Length Of Stay ] Reset State');
