import { createAction, props } from '@ngrx/store';

import { ZdlakeSearchRequest, ZdlakeZipCodeDestinations } from '../../models';

export const loadRequest = createAction(
  '[Zip Code Destinations] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);

export const loadSuccess = createAction(
  '[Zip Code Destinations] Load Success',
  props<{ items: ZdlakeZipCodeDestinations[]; order?: string }>(),
);

export const loadFailure = createAction(
  '[Zip Code Destinations] Load Failure',
  props<{ error: any }>(),
);

export const orderRequest = createAction(
  '[Zip Code Destinations] order request',
  props<{ order: string }>(),
);

export const orderSuccess = createAction(
  '[Zip Code Destinations] order success',
  props<{ order: string }>(),
);

export const resetState = createAction('[Zip Code Destinations] Reset State');
