import { createAction, props } from '@ngrx/store';

import { Invoice } from '../../models';

export const loadRequest = createAction(
  '[Suspended Invoices Widget] Load Request',
  props<{ propertiesIds: number[]; invoiceType: 'invoice' | 'receipt' }>(),
);
export const loadSuccess = createAction(
  '[Suspended Invoices Widget] Load Success',
  props<{ invoices: Invoice[] }>(),
);
export const loadFailure = createAction(
  '[Suspended Invoices Widget] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Suspended Invoices Widget] Reset State',
);
