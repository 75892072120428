import { createAction, props } from '@ngrx/store';

import { StatsWidgetResponse } from '../../models';

export const loadRequest = createAction(
  '[Occupancy Chart Widget] Load Request',
  props<{ propertiesIds: number[]; dateFrom: Date; dateTo: Date }>(),
);

export const loadLastYearRequest = createAction(
  '[Occupancy Chart Widget] Load Last Year Request',
  props<{ propertiesIds: number[]; dateFrom: Date; dateTo: Date }>(),
);

export const loadSuccess = createAction(
  '[Occupancy Chart Widget] Load Success',
  props<{
    occupancyData: StatsWidgetResponse;
    lineName: 'current_year' | 'last_year';
  }>(),
);
export const loadFailure = createAction(
  '[Occupancy Chart Widget] Load Failure',
  props<{ error: any }>(),
);

export const toggleLastYear = createAction(
  '[Occupancy Chart Widget] Toggle Last Year',
  props<{ visible: boolean }>(),
);

export const setLoading = createAction(
  '[Occupancy Chart Widget] Set Loading',
  props<{ isLoading: boolean }>(),
);

export const resetState = createAction('[Occupancy Chart Widget] Reset State');
