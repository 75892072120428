import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeDateOfCheckin } from '../../models';

export const featureAdapter: EntityAdapter<ZdlakeDateOfCheckin> =
  createEntityAdapter<ZdlakeDateOfCheckin>({
    selectId: (model) => model.date,
  });

export interface State extends EntityState<ZdlakeDateOfCheckin> {
  isLoading?: boolean;
  error?: any;
  originalOrder: string[];
  order: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  originalOrder: null,
  order: null,
});
