import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { StatsWidgetResponse } from '../../models';
import { StatsWidgetService } from '../../services/stats-widgets.service';

import * as fromActions from './actions';

@Injectable()
export class DailyOccupancyChartWidgetStoreEffects {
  constructor(
    private dataService: StatsWidgetService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, currentDate }) =>
        this.dataService.load(propertiesIds, currentDate, currentDate).pipe(
          map(({ data }: IResponseSuccess<StatsWidgetResponse>) =>
            fromActions.loadSuccess({ data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
