import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ZdlakeQuestionsDatesOfStay } from '../../models';
import { ChartLineSingle } from '../../models/objects/chart-line';

export const featureAdapter: EntityAdapter<ZdlakeQuestionsDatesOfStay> =
  createEntityAdapter<ZdlakeQuestionsDatesOfStay>({
    selectId: (model) => model.date,
  });

export interface State extends EntityState<ZdlakeQuestionsDatesOfStay> {
  isLoading?: boolean;
  error?: any;
  chartData: ChartLineSingle[];
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  chartData: [],
});
