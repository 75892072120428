import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Invoice } from '../../models';
import { InvoicesService } from '../../services/invoices.service';

import * as fromActions from './actions';

@Injectable()
export class RejectedInvoicesWidgetStoreEffects {
  constructor(
    private dataService: InvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds: property_id }) =>
        this.dataService
          .loadInvoicesBySdiStatus({
            property_id,
            order: 'invoice_date:DESC|number:DESC',
            invoice_type: ['invoice', 'credit_note'],
            status_sdi: [2],
            registered: 0,
          })
          .pipe(
            map(({ data: invoices }: IResponseSuccess<Invoice[]>) =>
              fromActions.loadSuccess({ invoices }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );
}
