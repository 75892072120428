import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ExpiringPriceQuotationOptionsStoreEffects } from './effects';
import { expiringPriceQuotationOptionsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'expiringPriceQuotationOptions',
      expiringPriceQuotationOptionsReducer,
    ),
    EffectsModule.forFeature([ExpiringPriceQuotationOptionsStoreEffects]),
  ],
})
export class ExpiringPriceQuotationOptionsStoreModule {}
