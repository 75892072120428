import { Action, createReducer, on } from '@ngrx/store';

import { ZdlakeLengthOfStay } from '../../models';
import { ChartLineSingle } from '../../models/objects/chart-line';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
      chartData: onFormatData(items),
      total: (items || []).reduce((acc, { count }) => (acc = acc + count), 0),
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function LengthOfStayReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onFormatData(data: ZdlakeLengthOfStay[]): ChartLineSingle[] {
  return [...data]
    .sort((a, b) => a.lenght_of_stay - b.lenght_of_stay)
    .map(({ count, lenght_of_stay }) => {
      return {
        value: count,
        name: lenght_of_stay.toString(),
      };
    }) as ChartLineSingle[];
}
