import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DateFormatterService } from '../../core/services/date-formatter.service';
import { Invoice } from '../../models';
import { InvoicesService } from '../../services/invoices.service';

import * as fromActions from './actions';

@Injectable()
export class SuspendedInvoicesWidgetStoreEffects {
  constructor(
    private dataService: InvoicesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private dateFormatter: DateFormatterService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, invoiceType }) =>
        this.dataService
          .load({
            property_id: propertiesIds,
            order: 'payment_date:DESC',
            invoice_type: [invoiceType],
            registered: 1,
            status: 'suspended',
            now: this.dateFormatter.toServerFormat(new Date()),
          })
          .pipe(
            map(({ data: invoices }: IResponseSuccess<Invoice[]>) =>
              fromActions.loadSuccess({ invoices }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );
}
