import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.setFiltersValue, (state, { filtersValue }) => ({
    ...state,
    isLoading: false,
    error: null,
    filtersValue: {
      ...state.filtersValue,
      ...filtersValue,
    },
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function ZdlakeReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
