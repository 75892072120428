import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DateOfStayStoreEffects } from './effects';
import { DateOfStayReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('dateOfStay', DateOfStayReducer),
    EffectsModule.forFeature([DateOfStayStoreEffects]),
  ],
})
export class DateOfStayStoreModule {}
