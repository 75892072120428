import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Invoice } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'suspendedInvoicesWidget',
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectSuspendedInvoices: (
  state: object,
) => Invoice[] = featureAdapter.getSelectors(selectState).selectAll;
