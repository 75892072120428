import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SourceCountriesStoreEffects } from './effects';
import { SourceCountriesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('sourceCountries', SourceCountriesReducer),
    EffectsModule.forFeature([SourceCountriesStoreEffects]),
  ],
})
export class SourceCountriesStoreModule {}
