import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getFiltersValue = (state: State) => state.filtersValue;

export const selectState = createFeatureSelector<State>('zdlake');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectFiltersValue = createSelector(selectState, getFiltersValue);
