import { createAction, props } from '@ngrx/store';

import { ZdlakeSearchRequest, ZdlakeSourceCountries } from '../../models';

export const loadRequest = createAction(
  '[Source Countries] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);

export const loadSuccess = createAction(
  '[Source Countries] Load Success',
  props<{ items: ZdlakeSourceCountries[]; order: string }>(),
);

export const loadFailure = createAction(
  '[Source Countries] Load Failure',
  props<{ error: any }>(),
);

export const orderRequest = createAction(
  '[Source Countries] order request',
  props<{ order: string }>(),
);

export const orderSuccess = createAction(
  '[Source Countries] order success',
  props<{ order: string }>(),
);

export const resetState = createAction('[Source Countries] Reset State');
