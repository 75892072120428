import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ExpiringReservationsOptionsStoreEffects } from './effects';
import { expiringReservationsOptionsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'expiringReservationsOptions',
      expiringReservationsOptionsReducer,
    ),
    EffectsModule.forFeature([ExpiringReservationsOptionsStoreEffects]),
  ],
})
export class ExpiringReservationsOptionsStoreModule {}
