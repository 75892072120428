import { Action, createReducer, on } from '@ngrx/store';
import { flatten, uniqueId } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';
import { CompletedAccommodation } from '../../models';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadsSuccess, (state, { items }) => {
    const data = flatten<CompletedAccommodation>(
      Object.keys(items).map((property_id) => [
        ...items[property_id].map((acc) => ({
          ...acc,
          id: uniqueId(),
          property_id,
        })),
      ]),
    );

    return fromState.featureAdapter.setAll(data, {
      ...state,
      isLoading: false,
      error: null,
    });
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function completedAccommodationsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
