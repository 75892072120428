import { createAction, props } from '@ngrx/store';

import { ZdlakeSearchRequest, ZdlakeSourceCountries } from '../../models';

export const loadRequest = createAction(
  '[Source Countries Chart] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Source Countries Chart] Load Success',
  props<{ items: ZdlakeSourceCountries[] }>(),
);
export const loadFailure = createAction(
  '[Source Countries Chart] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Source Countries Chart] Reset State');
