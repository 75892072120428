import { createAction, props } from '@ngrx/store';

import { ZdlakeSearchRequest, ZdlakeTypeOfGuests } from '../../models';

export const loadRequest = createAction(
  '[Type Of Guests] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[Type Of Guests] Load Success',
  props<{ items: ZdlakeTypeOfGuests[] }>(),
);
export const loadFailure = createAction(
  '[Type Of Guests] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Type Of Guests] Reset State');
