import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getChartData = (state: State) => state.chartData;

export const getTotal = (state: State) => state.total;

export const selectState = createFeatureSelector<State>('lengthOfStay');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectChartData = createSelector(selectState, getChartData);

export const selectTotal = createSelector(selectState, getTotal);

export const selectEntities =
  featureAdapter.getSelectors(selectState).selectEntities;
